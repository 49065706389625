<template>
  <div class="main">
    <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="180px">
      <div class="top">
        <h2>学生基础信息:</h2>
        <el-form-item v-for="(i,n) in objArr" v-if="type===4?true:n!==4" :key="i.key" :label="i.label">
          {{ n === 0 ? `${formData[i.key]}(${formData.student_no})` : formData[i.key] }}
        </el-form-item>
      </div>
      <div class="top">
        <h2>学生休学信息:</h2>
        <el-form-item v-for="i in objArr_" :key="i.key" :label="i.label">
          {{ formData[i.key] }}
        </el-form-item>
      </div>
      <div class="top">
        <h2>学生复学信息:</h2>
        <el-form-item label="复学时间" prop="end_time">
          <el-date-picker v-model="form.end_time" placeholder="请选择复学时间" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        </el-form-item>
        <el-form-item label="复学后行政班" prop="suspend_schooling_class_id">
          <el-select v-model="form.suspend_schooling_class_id" placeholder="请选择复学后行政班">
            <el-option v-for="i in options" :key="i.class_id" :label="i.class_name" :value="i.class_id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.suspend_schooling_notes" :autosize="{minRows: 4}" maxlength="300" placeholder="请输入复学备注" show-word-limit style="width: 400px" type="textarea"></el-input>
        </el-form-item>
      </div>
      <div class="top">
        <h2>操作记录:</h2>
        <div style="width: 50%">
          <el-row :gutter="20">
            <el-col v-for="(i,n) in objArr__" :key="i.key" :span="12">
              <el-form-item :label="i.label">
                {{ formData[i.key] }}
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="top">
        <el-button type="primary" @click="onOk">保存</el-button>
        <el-button @click="$router.back()">返回</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>

export default {
  _config: {"route": {"path": "edit", "meta": {"title": "复学", style: 2}}},
  data() {
    return {
      objArr: [
        {label: '学生姓名', key: 'student_name'},
        {label: '校区', key: 'school_name'},
        {label: '年级', key: 'grade_name'},
        {label: '行政班', key: 'class_name'},
        {label: '学生状态', key: 'student_status'},
      ],
      objArr_: [
        {label: '开始休学时间', key: 'start_time'},
        {label: '预计复学时间', key: 'estimate_end_time'},
        {label: '休学原因', key: 'suspend_schooling_reason'}
      ],
      objArr__: [
        {label: '休学操作人', key: 'creator'},
        {label: '操作时间', key: 'created_at'},
      ],
      type: 0,
      formData: {},
      form: {
        id: this.$route.query.id,
        end_time: '',
        suspend_schooling_class_id: '',
        suspend_schooling_notes: '',
      },
      options: [],
      rules: {
        end_time: [
          {required: true, message: '请选择复学时间', trigger: 'change'},
        ],
        suspend_schooling_class_id: [
          {required: true, message: '请选择复学后行政班', trigger: 'change'},
        ],
        suspend_schooling_notes: [
          {required: true, message: '请输入复学备注', trigger: 'change'},
        ]
      }
    }
  },
  methods: {
    onOk() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$_axios2.post('api/student/student-adjust/suspend-schooling-restore', this.form, {logic: 1}).then(res => {
            this.$message.success('提交成功')
            this.$router.back()
          })
        }
      })
    }
  },
  created() {
    this.$_axios2.get('api/student/student-adjust/suspend-schooling-details', {params: {id: this.$route.query.id}}).then(res => {
      this.formData = res.data.data
      this.$_axios2.get('api/student/student-adjust/resume-schooling-class', {params: {student_id: res.data.data.student_id}}).then(res => {
        this.options = res.data.data
      })
    })
  },
}
</script>

<style lang="scss" scoped>
.main {
  padding-right: 12px;
  box-sizing: border-box;
}

h2 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 12px;
  margin-bottom: 24px;
}

.top {
  min-height: 18px;
  background: #FFF;
  border-radius: 12px;
  padding: 16px;
  box-sizing: border-box;
  margin-bottom: 24px;
}
</style>